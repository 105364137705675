@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;700&family=Text+Me+One&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');

// @import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
// @import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
// @import '~admin-lte/plugins/ion-rangeslider/css/ion.rangeSlider.css';

// @import '~admin-lte/plugins/jquery/jquery.min.js';
@import '~admin-lte/dist/css/adminlte.min.css';
// @import '~admin-lte/dist/css/adminlte.css';

@import '~react-toastify/scss/main';

// @font-face {
//   font-family: 'Uni Sans Thin';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../fonts/universalis-adf-std.regular.otf'); /* IE9 Compat Modes */
//   src: local('Uni Sans Thin'), local('UniSansThin'),
//        url('../fonts/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/open-sans-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
// }
:root {
  --font-family-sans-serif: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: 'Open Sans', sans-serif,SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

body {
  font-family: 'Text Me One', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
}

// .tooltip {
//   font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// }

// .popover {
//   font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// }

// .text-monospace {
//   font-family: 'Open Sans', sans-serif, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
// }

// .mapael .mapTooltip {
//   font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  
// }

#root {
    width: 100vw;
    height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.navbar-nav > .user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.navbar .nav > {
    // background:none;
    background-color: black;
  }

  .nav-sidebar{
    overflow-x: hidden;
  }

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: transparent;
  color: #d36233;
}

.irs--flat .irs-bar{
  background-color: #e37039
}

.irs--flat .irs-to {
  background-color: #e37039
}

.irs--flat .irs-from {
  background-color: #e37039
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #d36233;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.layout-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }

  .layout-sm-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }

  .layout-md-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }

  .layout-lg-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }

  .layout-xl-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }

  .layout-navbar-fixed .dark-mode .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }

  .layout-navbar-fixed .dark-mode .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }

@media (min-width: 576px) {
    .layout-sm-navbar-fixed .dark-mode .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
      background-color: #fff;
    }
}

@media (min-width: 768px) {
    .layout-md-navbar-fixed .dark-mode .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
      background-color: #fff;
    }
}

@media (min-width: 992px) {
    .layout-lg-navbar-fixed .dark-mode .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
      background-color: #fff;
    }
}

@media (min-width: 1200px) {
    .layout-xl-navbar-fixed .dark-mode .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
      background-color: #fff;
    }
}