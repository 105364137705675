 .mercadoWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: '30rem';
    grid-gap: 40px;
    grid-column-gap: 30px;
} 

.mercadoWrapper1366{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: '30rem';
    grid-gap: 10px;
    grid-column-gap: 5px;
}

.mercadoWrapper1600{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: '30rem';
    grid-gap: 40px;
    grid-column-gap: 120px;
}

.mercadoWrapper1920{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: '30rem';
    grid-gap: 10px;
    grid-column-gap: 5px;
}