input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: #d36233;
    color: #000;
    outline: #d36233;
}

::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
/* ::-webkit-calendar-picker-indicator {
    filter: invert(1);
} */