.section-divs {
    border: 0px solid white;   
  }
  
  .selected {
    outline: 5px solid #e37039;
  }

  .ion-icon:before{
    content: '';
    position: 'absolute';
    width: '100%';
    height: '100%';
}