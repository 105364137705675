.containerw {  display: grid;
  grid-template-columns: 1fr 1.2fr 0.8fr;
  grid-template-rows: 1fr 1.5fr 0.5fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 
    "Top Top Esquerda"
    "Direita Direita Esquerda"
    "Baixo Baixo Esquerda"; 
  height: 100vh;
}

.text{
  font-size: clamp(1em, 2em + 1vw, 4.5em);
  font-family: 'Text me one';
}

.text2{
  font-size: clamp(1em, 1em + 2vw, 2.5em)
}

.Direita { 
  grid-area: 1 / 1 / 4 / 3;
  background-image: url(../img/1.avif);
 }

.Esquerda { 
  grid-area: 1 / 3 / 4 / 4;
  background-size: cover;
  background-repeat: no-repeat;
 }

 .EsquerdaVideo {
  grid-area: 1 / 3 / 4 / 4;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  object-fit: cover;

  /* position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%; */
 }

.Baixo { 
  grid-area: 3 / 1 / 4 / 3;
  /* background-color: aqua; */
 }

.Centro {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Logo Logo ."
    "Logo Logo ."
    "Text Text Text";
  grid-area: 2 / 1 / 3 / 3;
}

.Top { grid-area: Top;
background-color: aqua; }
.Logo { grid-area: Logo; }

.Text { grid-area: Text; }

.container2 {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.8fr 1.7fr 0.5fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "topo topo topo"
    "Centro Centro Centro"
    "base base base";
    height: 100vh;
    min-height: 100vh;
}

.container7 {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.9fr 0.9fr 1.2fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "topo topo topo"
    "Centro Centro Centro"
    "base base base";
    height: 100vh;
    min-height: 100vh;
}

.topo2 { grid-area: topo; }

.Centro7 { grid-area: Centro; background-color: #cac6c2 ;}

.base7 { grid-area: base; background-color: #cac6c2 ;}

.Centro2{ grid-area: Centro; margin: -120px; background-image: url(../img/1.avif);}
.Centro2Minimize{ grid-area: Centro; margin: -60px; background-image: url(../img/1.avif);}

.Centro3 { grid-area: Centro; margin-top: -300px; background-image: url(../img/1.avif);}
.Centro3Minimize { grid-area: Centro; margin-top: -200px; background-image: url(../img/1.avif);}

.base2 { grid-area: base; background-image: url(../img/1.avif) }

.sobra2 { grid-area: sobra; background-image: url(../img/1.avif)}

.topo3 { grid-area: topo; background-image: url(../img/TOPO3.png); background-size: cover; background-repeat: no-repeat; }

.videopg3 { grid-area: topo; }

.videopg4 { grid-area: topo; }

.videopg5 { grid-area: topo; }

.videopg6 { grid-area: topo;  }

.videopg7 { grid-area: topo;}

.topo4 { grid-area: topo; background-image: url(../img/TOPO4.png); background-size: cover; background-repeat: no-repeat; }

.topo5 { grid-area: topo; background-image: url(../img/TOPO5.png); background-size: cover; background-repeat: no-repeat; }

.topo6 { grid-area: topo; background-image: url(../img/TOPO6_1.png);background-size: cover; background-repeat: no-repeat; }

.topo7 { grid-area: topo; background-color: #cac6c2 ; }

.seta2 {
  /* animation: myAnim 3s cubic-bezier(0.11, 0, 0.5, 0) 0s infinite normal none; */
  /* margin-left:400px; */
  /* margin-left: '50%'; */
  /* justify-content: center;
  align-items: center; */
  display: 'flex';
  cursor: 'pointer';
  max-width: 250px
}

@keyframes myAnim {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateY(-45px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateY(-24px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateY(-12px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateY(-6px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateY(-4px);
	}

	25%,
	55%,
	75%,
	87% {
		animation-timing-function: ease-out;
		transform: translateY(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0px);
	}
}

/* teste redimencionamento */
.containerR {  display: grid;
  grid-template-columns: 2.4fr 2fr 0.04fr;
  grid-template-rows: 0.8fr 1.7fr 0.5fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "esquerda Centro direita"
    "esquerda Centro direita"
    "esquerda Centro direita";
  height: 100vh;
}

.topoR { grid-area: topo; background-image: url(../img/TOPO3.png); background-size: cover; background-repeat: no-repeat;  }

.CentroR {  /* display: grid;
  grid-template-columns: 1.6fr 0.1fr 1.5fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "esquerda direita direita"
    "esquerda direita direita"
    "esquerda direita direita"; */
    min-height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat; 
  grid-area: Centro;
}

.esquerdaR { grid-area: esquerda;   background-image: url(../img/1.avif);
  background-size: cover;
  background-repeat: no-repeat; }

/* .direitaR { grid-area: direita; background-image: url(../img/COMPUTADOR1.avif); background-size: calc(100% - 100px) auto; background-repeat: no-repeat;  } */
.direitaR { grid-area: direita;
  /* background-image: url(../img/1.avif); */
  background-color: #e37039;
  background-size: cover;
  background-repeat: no-repeat; 

}

.baseR { grid-area: base;   background-image: url(../img/1.avif);
  background-size: cover;
  background-repeat: no-repeat; }
  
  
.modalPlanos .modal-content{
  background-color: 'rgba(0, 0, 0, 0.5)';
  
} 

.modal-backdrop
{
    opacity:0.5 !important;
}
 
.modal-content{
  background-color: 'rgba(0, 0, 0, 0.4)';
}

.alvo {
  background-image: url('src/img/diretoSprite.avif');
  display: inline-block;
  width: 150px; /* Ajuste conforme necessário */
  height: 150px; /* Ajuste conforme necessário */
  background-size: 200% 100% ; /* Largura total e altura para duas imagens empilhadas */
  background-position: 0 0; /* Posição inicial na parte superior da sprite */
}

.alvo:hover {
  background-position: -100% 0 ; /* Move para cima (mostra a segunda imagem) */
}

.ferramentas{
  background-image: url('src/img/customizavelSprite.avif');
  display: inline-block;
  width: 150px; /* Ajuste conforme necessário */
  height: 150px; /* Ajuste conforme necessário */
  background-size: 200% 100% ; /* Largura total e altura para duas imagens empilhadas */
  background-position: 0 0; /* Posição inicial na parte superior da sprite */
}

.ferramentas:hover{
  background-position: -100% 0 
}

.labirinto{
  background-image: url('src/img/descomplicadoSprite.avif');
  display: inline-block;
  width: 150px; /* Ajuste conforme necessário */
  height: 150px; /* Ajuste conforme necessário */
  background-size: 200% 100% ; /* Largura total e altura para duas imagens empilhadas */
  background-position: 0 0;
}

.labirinto:hover{
  background-position: -100% 0 

}

.tempo{
  background-image: url('src/img/rapidoSprite.avif');
  display: inline-block;
  /* width: 10px; /* Ajuste conforme necessário */
  height: 150px; /* Ajuste conforme necessário */ 
  background-size: 200% 100% ; /* Largura total e altura para duas imagens empilhadas */
  background-position: 0 0;
}

.tempo:hover{
  background-position: -100% 0 

}
 