/* RESUMO */

.tabledata{
    color: #d36233;
    font-weight: 700;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    font-size: 16px;
    border-bottom: 1px solid #e37039
}

.text-resumo{
    width: 300px;
    font-size: 18px;
    font-weight: 400;
}

.text-num{
    width: 90px;
    color: #000;
    display: flex;
    justify-content: center;
}

.trending{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
}

.tabledata1920{
    color: #d36233;
    font-weight: 700;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 95px;
    font-size: 20px;
    border-bottom: 1.5px solid #e37039
}

.text-resumo1920{
    width: 400px;
    font-size: 22px;
    font-weight: 400;
}

.resumo{
    width: 38rem;
    height: 30.5rem;
    border-radius: 1rem;
}

.resumo1920{
    width: 60rem;
    height: 44rem;
    border-radius: 1rem;
}

.resumomes{
    width: 16rem;
    height: 30.5rem;
    border-radius: 1rem;
}

.resumomes1920{
    width: 32rem;
    height: 44rem;
    border-radius: 1rem;
}

.tablemes1920{
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95px;
    font-size: 24px;
    border-bottom: 1.5px solid #e37039
}

.tablemes{
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    font-size: 16px;
    border-bottom: 1px solid #e37039
}

/* RESUMO */

/* USUARIOS */


.idtext{
    width: 90px;
    text-align: center;
}

.idhead{
    width: 90px;
    display: flex;
    justify-content: center;
}

.nometext{
    width: 335px;
    text-align: center;
}

.emailtext{
    width: 285px;
    text-align: center;
}

.othertext{
    width: 95px;
    text-align: center;
}

 /* 1920 */

.idtext1920{
    width: 140px;
    text-align: center;
}

.idhead1920{
    width: 140px;
    display: flex;
    justify-content: center;
}

.nometext1920{
    width: 340px;
    text-align: center;
}

.emailtext1920{
    width: 380px;
    text-align: center;
}

.othertext1920{
    width: 160px;
    text-align: center;
}

/* USUARIOS */

/* CANCELAMENTO */

.idcanceltext{
    width: 160px;
    text-align: center;
}

.idcancelhead{
    width: 160px;
    display: flex;
    justify-content: center;
}

.nomecanceltext{
    width: 280px;
    text-align: center;
}

.emailcanceltext{
    width: 320px;
    text-align: center;
}

.timetext{
    width: 160px;
    text-align: center;
}

.idcanceltext1920{
    width: 180px;
    text-align: center;
}

.idcancelhead1920{
    width: 180px;
    display: flex;
    justify-content: center;
}

.nomecanceltext1920{
    width: 420px;
    text-align: center;
}

.emailcanceltext1920{
    width: 420px;
    text-align: center;
}

.timetext1920{
    width: 260px;
    text-align: center;
}

/* CANCELAMENTO */

.carddashboard{
    width: 90%; 
    height: 30rem;
    border-radius: 1rem
}

.carddashboard1920{
    width: 90%; 
    height: 42rem;
    border-radius: 1rem

}

/* CONFIG */

.cardconfig{
    width: 32rem;
    height: 26rem;
    border-radius: 5%;
    margin: 20px;
}

.cardplanos{
    width: 40rem;
    height: 20rem;
    border-radius: 5%;
    margin: 20px;
}

.idconfigtext{
    width: 200px;
    text-align: center;
}

.idconfighead{
    width: 220px;
    display: flex;
    justify-content: center;
}

.nomeconfigtext{
    width: 220px;
    text-align: center;
}

.perfiltext{
    width: 175px;
    text-align: center;
}

.planotext{
    width: 260px;
    text-align: center;
}

.idconfigtext1360{
    width: 85px;
    text-align: center;
}

.idconfighead1360{
    width: 90px;
    display: flex;
    justify-content: center;
}

.nomeconfigtext1360{
    width: 200px;
    text-align: center;
}

.perfiltext1360{
    width: 130px;
    text-align: center;
}

.planotext1360{
    width: 190px;
    text-align: center;
}

.cardconfig1920{
    width: 44rem;
    height: 36rem;
    margin: 20px;
}

.cardplanos1920{
    width: 54rem;
    height: 28rem;
    margin: 20px;
}

/* CONFIG */