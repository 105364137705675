/* @import url('https://fonts.googleapis.com/css?family=Montserrat:400,800'); */

.base {
  /* background: #f6f5f7; */
  /* background-image: url(../../assets/pulverizacao.jpg); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* min-width: 90vw; */
  /* background-repeat: no-repeat; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* font-family: "Montserrat", sans-serif; */
  /* height: 70vh; */
  /* margin: -40px 0 50px; */
}

.usuario {
  /* border-radius: 30%; */
  position: absolute;
  top: -35px;
  left: 334px;
  z-index: 99999;
}

.form-container h1 {
  color: #e37039;
  font-weight: bold;
  font-size: 20px;
  margin: 0;
}

.overlay-panel p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.form-container span {
  color: #e37039;
  font-size: 12px;
}

.social-container a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}
.form-container a {
  color: #e37039;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.form-container button {
  /* border-radius: 20px; */
  border: 1px solid #e37039;
  background-color: #e37039;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 60px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.overlay-panel button {
  /* border-radius: 20px; */
  border: 1px solid #ffffff;
  background-color: white;
  color: #e37039;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 60px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.overlay-panel button:active {
  transform: scale(0.95);
}

.overlay-panel button:focus {
  outline: none;
}

.overlay-panel button.ghost {
  /* background-color: transparent; */
  border-color: #ffffff;
}

.form-container button:active {
  transform: scale(0.95);
}

.form-container button:focus {
  outline: none;
}

.form-container button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.form-container form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.form-container input:not(.mascara) {
  /* background-color: #eee; */
  border: none;
  /* border-radius: 20px; */
  border: 1px solid #e37039;
  padding: 4px 15px;
  margin: 8px 0;
  width: 100%;
}

.form-container input:focus {
  outline: none;
}

.containerlogin {
  background-color: #fff;
  /* border-radius: 30px 80px; */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  /* width: 768px; */
  width: 55vw;
  /* width: 608px; */
  max-width: 100%;
  /* min-height: 480px; */
  /* min-height: 380px; */
  min-height: 320px;
}

.containerloginMobile {
  background-color: #fff;
  /* border-radius: 30px 80px; */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  /* width: 768px; */
  width: 55vmax;
  /* width: 608px; */
  max-width: 100%;
  /* min-height: 480px; */
  /* min-height: 380px; */
  min-height: 320px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.containerlogin.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.containerlogin.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.containerlogin.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #e37039;
  /* background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.containerlogin.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-panel input {
  border: none;
  /* border-radius: 20px; */
  border: 1px solid #f0f1f1;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.overlay-left {
  transform: translateX(-20%);
}

.containerlogin.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.containerlogin.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-img {
  /* width: 100px; */
  max-width: 25px;
}

.social-container a {
  border: 1px solid #dddddd;
  /* border-radius: 50%; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
