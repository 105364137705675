.perfil-id{
    width:5vw;
    text-align: center;
}

.perfil-nome{
    width:15vw;
    text-align: center;
}

.perfil-perfil{
    width:10vw;
    text-align: center;
}

.perfil-data{
    width:15vw;
    text-align: center;
}

.perfil-reativar{
    width:6vw;
    text-align: center;
}