#inputID::placeHolder {
    color: #fff;
    opacity: 1;
}
input:focus{
    box-shadow: 0 0 0 0;
    outline: 0;
}

#inputCotacao::placeHolder {
    color: #fff;
    opacity: 1;
}

#inputTeorico::placeHolder {
    color: #fff;
    opacity: 1;
}

#inputJuros::placeHolder {
    color: #fff;
    opacity: 1;
}

#inputImplicita::placeHolder {
    color: #fff;
    opacity: 1;
}

.custom-popper {
    position: absolute;
    will-change: transform;
    top: 0;
    left: 0;
    transform: translate3d(1500px, 153px, 0);
  }

  .font-loader {
    font-family: 'Roboto'
  }